import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const paymentPlansApi = createApi({
  reducerPath: 'paymentPlans',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    
    prepareHeaders: (headers, { getState }) => {
      // Directly access the auth state
      const authState = getState().auth;
      const token = authState.userData?.token; // Assuming the token is stored in userData

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      
      headers.set('Accept', 'application/json');
      return headers;
    },
  }),
  
  endpoints: (builder) => ({
    getPaymentPlans: builder.query({
      query: () => ({
        url: '/admin/paymentPlans',
        method: 'GET',
      }),
      providesTags: ['PaymentPlan'],
    }),

    addPaymentPlan: builder.mutation({
      query: (paymentPlan) => ({
        url: '/admin/paymentPlans',
        method: 'POST',
        body: paymentPlan,
      }),
      invalidatesTags: ['PaymentPlan'],
    }),

    updatePaymentPlan: builder.mutation({
      query: (payload) => ({
        url: `/admin/paymentPlans/${payload.id}`,
        method: 'PUT',
        body: payload.dataToUpdate,
      }),
      invalidatesTags: ['PaymentPlan'],
    }),

    deletePaymentPlan: builder.mutation({
      query: (id) => ({
        url: `/admin/paymentPlans/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PaymentPlan'],
    }),
  }),
});

export const {
  useGetPaymentPlansQuery,
  useAddPaymentPlanMutation,
  useUpdatePaymentPlanMutation,
  useDeletePaymentPlanMutation,
} = paymentPlansApi;

export { paymentPlansApi };
