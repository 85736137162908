import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const featuresApi = createApi({
  reducerPath: 'features',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    // baseUrl: 'http://127.0.0.1:3333/',
    prepareHeaders: (headers, { getState }) => {
      // Directly access the auth state
      const authState = getState().auth;
      const token = authState.userData?.token; // Assuming the token is stored in userData

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      headers.set('Accept', 'application/json');
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getFeatures: builder.query({
      query: () => ({
        url: 'admin/features',
        method: 'GET',
      }),
      providesTags: ['Features'],
    }),

    addFeature: builder.mutation({
      query: (feature) => ({
        url: 'admin/features',
        method: 'POST',
        body: feature,
      }),
      invalidatesTags: ['Features'],
    }),

    updateFeature: builder.mutation({
      query: (payload) => ({
        url: `admin/features/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['Features'],
    }),

    deleteFeature: builder.mutation({
      query: (id) => ({
        url: `admin/features/${id}`,
        method: 'DELETE',
      }),
    }),
    invalidatesTags: ['Features'],
  }),
});

export const { useGetFeaturesQuery, useAddFeatureMutation, useUpdateFeatureMutation, useDeleteFeatureMutation } =
  featuresApi;
export { featuresApi };
